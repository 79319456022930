<template>
  <div>
    <div class="apartFormNext">
    <el-form
      :model="viewItems"
      ref="viewItems"
      label-width="200px"
      :inline="true"
      class="loginTypeChoose"
      style="text-align: left;"
    >
      <el-card class="box-card" shadow="hover" style="margin:0 auto;">
        <!-- 服务器提供商(单选框按钮组) -->
        <el-form-item
          :label="$options.nodesCommonOptions['cloud'].name"
          :placeholder="$options.nodesCommonOptions['cloud'].placeholder"
          :rules="$options.nodesCommonOptions['cloud'].rules"
          :prop="$options.nodesCommonOptions['cloud'].name"
        >
          <el-radio-group size="large" v-model="viewItems['cloud']">
            <el-radio-button
              v-for="(radio, index) in formItemOptions['cloud']"
              :key="index"
              :label="radio.label"
              >{{ radio.text }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>

        <!-- 登录类型(单选框按钮组) -->
        <el-form-item
          :label="$options.nodesCommonOptions['loginTypes'].name"
          :placeholder="$options.nodesCommonOptions['loginTypes'].placeholder"
          :rules="$options.nodesCommonOptions['loginTypes'].rules"
          :prop="$options.nodesCommonOptions['loginTypes'].name"
        >
          <el-radio-group size="large" v-model="viewItems['loginTypes']">
            <el-radio-button
              v-for="(radio, index) in formItemOptions['loginTypes']"
              :key="index"
              :label="radio.label"
              >{{ radio.text }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>

        <!-- sshKeyName输入框 -->
        <el-form-item
          v-show="viewItems['loginTypes'] === 'sshKeyName'"
          :label="$options.nodesCommonOptions['sshKeyName'].label"
          :placeholder="$options.nodesCommonOptions['sshKeyName'].placeholder"
          :rules="$options.nodesCommonOptions['sshKeyName'].rules"
          :prop="$options.nodesCommonOptions['sshKeyName'].name"
        >
          <el-input
            v-model="viewItems['sshKeyName']"
            :type="$options.nodesCommonOptions['sshKeyName'].type"
          ></el-input>
        </el-form-item>

        <!-- loginUserName输入框 -->
        <el-form-item
          v-show="viewItems['loginTypes'] === 'loginPassWord'"
          :label="$options.nodesCommonOptions['loginUserName'].label"
          :placeholder="
            $options.nodesCommonOptions['loginUserName'].placeholder
          "
          :rules="$options.nodesCommonOptions['loginUserName'].rules"
          :prop="$options.nodesCommonOptions['loginUserName'].name"
        >
          <el-input
            v-model="viewItems['loginUserName']"
            :type="$options.nodesCommonOptions['loginUserName'].type"
          ></el-input>
        </el-form-item>

        <!-- loginPassWord输入框 -->
        <el-form-item
          v-show="viewItems['loginTypes'] === 'loginPassWord'"
          :label="$options.nodesCommonOptions['loginPassWord'].label"
          :placeholder="
            $options.nodesCommonOptions['loginPassWord'].placeholder
          "
          :rules="$options.nodesCommonOptions['loginPassWord'].rules"
          :prop="$options.nodesCommonOptions['loginPassWord'].name"
        >
          <el-input
            autofocus
            v-model="viewItems['loginPassWord']"
            :type="$options.nodesCommonOptions['loginPassWord'].type"
          ></el-input>
        </el-form-item>

        

        <!-- 确定按钮 -->
        <el-form-item>
          <el-button type="primary" @click="logInfoSave('viewItems')" class="saveButton"
            >保存</el-button
          >
        </el-form-item>
      </el-card>
    </el-form>

    <div v-if="helpers.nodeFormsDisplay || this.$store.getters.reqData.nodesOptions.length > 0">
      <el-main
        v-for="nodeOpts in nodesOptions"
        :key="nodeOpts.key"
        class="nodesForms"
      >
        <HuaweiNodeForm
          class="formCompo"
          :nodeOptions="nodeOpts"
          ref="childForms"
          @validationResCome="getValidation"
          @deleteNodeForm="deleteNodeForm"
        ></HuaweiNodeForm>
      </el-main>
      <div style="height: 60px;">
      <el-button type="primary" @click="addNodeForm" class="newNodeButton" 
        >新增节点</el-button
      ></div>
      <!-- <el-button type="primary" @click="test">test</el-button> -->
    </div>
      <div id="zhanwei"></div>
      <PrevNext
        @prev-click="this.$router.push('/form/huaweiAccess/huaweiCluster')"
        @next-click="onNextClick"
      ></PrevNext>
    </div>
  </div>
</template>

<script>
import HuaweiNodeForm from "./HuaweiNodeFormPlus.vue";
import PrevNext from "../../../components/Common/PrevNext.vue";
import { PROCESS_ACTIVE } from "../../../assets/constant.js";
import { v4 as uuidv4 } from "uuid";
import { nodesCommonOptions } from "../../../assets/formObj/huawei";

export default {
  data() {
    return {
      nodesOptions: [
        {
          type: "default",
          cloud: "huawei",
          nodePoolName: "default-nodes",
          nodeNums: 1,
          nodeTypes: ["s6.large.2"],
          sshKeyName: "",
          systemDiskCategory: "SAS",
          systemDiskSize: "50",
          nodeLabels: {
            nodestype: "spark-applications-driver-nodes",
          },
          resourceTags: {
            team: "dmetasoul",
          },
          dataDiskCategory: "SAS",
          dataDiskSize: "100",
          loginPassWord: "Dmetasoul_@123_pass",
          loginUserName: "root",
          nodeAz: "cn-southwest-2a",
          nodeOS: "CentOS 7.6",
        },
        {
          type: "normal",
          cloud: "huawei",
          nodePoolName: "dirver-nodes",
          nodeNums: 1,
          nodeTypes: ["s6.large.2"],
          sshKeyName: "KeyPair-36d8",
          systemDiskCategory: "SAS",
          systemDiskSize: "50",
          nodeLabels: {
            nodestype: "spark-applications-driver-nodes",
          },
          resourceTags: {
            team: "dmetasoul",
          },
          dataDiskCategory: "SAS",
          dataDiskSize: "100",
          loginPassWord: "Dmetasoul_@123_pass",
          loginUserName: "root",
          nodeAz: "cn-southwest-2a",
          nodeOS: "CentOS 7.6",
        },
        {
          type: "normal",
          cloud: "huawei",
          nodePoolName: "application-nodes",
          nodeNums: "1",
          sshKeyName: "",
          systemDiskCategory: "SAS",
          systemDiskSize: "50",
          dataDiskCategory: "SAS",
          dataDiskSize: "100",
          loginPassWord: "Dmetasoul_@123_pass",
          loginUserName: "root",
          nodeAz: "cn-southwest-2a",
          nodeOS: "CentOS 7.6",
          nodeLabels: {
            nodestype: "spark-applications-nodes",
          },
          resourceTags: {
            team: "dmetasoul",
          },
          nodeTypes: ["s6.large.2"],
        },
      ],
      viewItems: {
        // 与当前组件的表单项(视图)绑定
        cloud: "huawei",
        loginTypes: "loginPassWord",
        sshKeyName: "",
        loginPassWord: "",
        loginUserName: "",
      },
      formItemOptions: {
        // 云提供商
        cloud: [
          {
            text: "华为云",
            label: "huawei",
          },
        ],

        // 登录方式
        loginTypes: [
          {
            text: "sshKeyName",
            label: "sshKeyName",
          },
          {
            text: "loginPassWord",
            label: "loginPassWord",
          },
        ],
      },

      helpers: {
        nodeFormsKeys: [], // 建立key与this.nodesOptions数组下标的对应
        validationArr: [true, true, true],
        nodeFormsDisplay: false,
      },
    };
  },

  components: {
    HuaweiNodeForm,
    PrevNext,
  },

  created() {
    // 导航栏状态的同步
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.NODE);

    // 如果用户已经提交过节点配置表单, 获取之前的输入
    if (this.$store.getters.reqData.nodesOptions.length > 0) {
      this.getOriUserInput();
    }
    // 添加key和required(是否为必填表单)等信息
    this.addKeysAndRequired(this.nodesOptions);


    // 本页面表单部分的静态数据, 如表单类型, 验证规则等
    this.$options.nodesCommonOptions = nodesCommonOptions; // 所有节点共同的选项,如登录账户和密码等
    
    // 新增节点表单的样板数据,包含一些默认值等
    this.$options.nodeFormTemplate = {
      type: "",
      cloud: "huawei",
      nodePoolName: "",
      nodeNums: 1,
      nodeTypes: ["s6.large.2"],
      sshKeyName: "",
      systemDiskCategory: "SAS",
      systemDiskSize: "50",
      nodeLabels: {},
      resourceTags: {
        team: "dmetasoul",
      },
      dataDiskCategory: "SAS",
      dataDiskSize: "100",
      loginPassWord: "",
      loginUserName: "",
      nodeAz: "cn-southwest-2a",
      nodeOS: "CentOS 7.6",
    };
  },

  methods: {

    // 给每个nodeOption对象附加key和required信息
    addKeysAndRequired() {
      this.nodesOptions.forEach((nodeOpts) => {
        nodeOpts['key'] = uuidv4();
        nodeOpts['required'] = true;
        this.helpers.nodeFormsKeys.push(nodeOpts['key']);
      })
    },

    getOriUserInput() {
      const nodesOptions = this.$store.getters.reqData.nodesOptions;
      const nodeOptions = nodesOptions[0];  // 第一个和其它所有表单都行,这里取第一个节点配置表单

      // 配置节点配置表单的公共字段
      this.viewItems['cloud'] = nodeOptions.cloud;
      this.viewItems['loginTypes'] = nodeOptions['sshKeyName'] ? 'sshKeyName' : 'loginPassWord';
      this.viewItems['sshKeyName'] = nodeOptions['sshKeyName'];
      this.viewItems['loginPassWord'] = nodeOptions['loginPassWord'];
      this.viewItems['loginUserName'] = nodeOptions['loginUserName'];

      // 配置节点配置表单的个性字段
      this.nodesOptions = nodesOptions;
    },
    
    getIndex(key) {
      return this.helpers.nodeFormsKeys.indexOf(key);
    },

    getValidation(key, validation) {
      const ind = this.getIndex(key);
      this.helpers.validationArr[ind] = validation;
    },

    addNodeForm() {
      if (this.nodesOptions.length === 5) {
        // 这儿可以给用户一个提示
        this.$message({
          message: "已经创建了5个节点, 不能再创建了",
          type: "success",
        });
        return false;
      }

      // 检查之前填写的表单是否全部通过验证
      const validation = this.helpers.validationArr.every((validation) => validation);

      if (validation) {
        // 添加新节点配置表单
        const newNodeForm = {...this.$options.nodeFormTemplate};
        newNodeForm["key"] = uuidv4();
        newNodeForm["required"] = false;
        this.helpers.nodeFormsKeys.push(newNodeForm["key"]);
        this.nodesOptions.push(newNodeForm);
        this.helpers.validationArr.push(false); // false
      } else {
        this.$message({
          message: "请检查填写的表单",
          type: "warning",
        });
      }
    },

    /**
     * 检查是否有修改尚未保存
     * 如果使用mouseLeave事件, 该方法可以废弃
     */
    checkChanges() {
      this.$refs.childForms.forEach((childForm) => {
        childForm.getUnsavedChanges();
      });

      const keys = Object.keys(this.$store.getters.unsavedChanges);

      if (keys.length === 0) {
        return false;
      }

      keys.sort((key1, key2) => {
        return this.getIndex(key1) - this.getIndex(key2);
      });

      let message = "";

      keys.forEach((key) => {
        const ind = this.getIndex(key);
        const oneFormChanges = this.$store.getters.unsavedChanges[key];
        message += "第" + (ind + 1) + "个节点表单有未保存的修改: ";

        for (let desc of oneFormChanges) {
          let { field, oldVal, newVal } = desc;
          message += "字段" + field + ": " + oldVal + " => " + newVal + "; ";
        }

        message += "         ";
      });

      this.$message({
        message: message,
        type: "warning",
      });

      return true;
    },

    onNextClick() {
      const hasChanged = this.checkChanges();
      if (hasChanged) {
        return;  // 如果发生变化,则阻止用户进入下一步
      }

      let defaultNum = 0;  // 存储默认节点的数量
      
      for (let nodesOption of this.nodesOptions) {
        if (nodesOption.type === "default") defaultNum += 1;
      }

      if (defaultNum < 1) {
        this.$message({
          showClose: true,
          message: "默认节点数小于1，无法完成创建，请重新配置",
          type: "error",
        });
        return;
      }

      // 判断表单是否均通过了验证
      const validation = this.helpers.validationArr.every((valid) => valid);

      if (!validation) {
        this.$message({
          message: "请检查填写的表单项",
          type: "warning",
        });

        return;
      }

      // 删除发送时不需要的两个字段
      for (let nodeOpts of this.nodesOptions) {
        delete nodeOpts["key"];
        delete nodeOpts["required"];
        //如果用户选择loginPassWord方式则加密
        if(!!nodeOpts.loginPassWord)nodeOpts.loginPassWord=this.$getRsaCode(nodeOpts.loginPassWord)
      }

      this.$store.commit("setNodesOptions", this.nodesOptions);
      console.log(this.$store.getters.reqData);
      console.log("节点配置完成，即将进入应用配置...");
      this.$router.push("/form/appCommonConfig");
    },

    isValidated(unValidatedFields) {
      if (unValidatedFields.hasOwnProperty("sshKeyName")) {
        //
        return (
          this.viewItems["loginPassWord"] && this.viewItems["loginUserName"]
        );
      } else {
        return this.viewItems["sshKeyName"];
      }
    },

    clearSaveLogInfo() {
      // 用户选定的登录方式
      const loginType = this.viewItems.loginTypes;

      for (let nodeOpt of this.nodesOptions) {
        // 这种字面量的方式,以后可以改进之
        if (loginType === "loginPassWord") {
          nodeOpt.sshKeyName = "";
        } else if (loginType === "sshKeyName") {
          nodeOpt.loginPassWord = "";
          nodeOpt.loginUserName = "";
        } else {
          // 不需要做什么, 应该不会出现这种情况, 因为是让用户选择的
        }
      }

      // 改变模板中的内容
      if (loginType === "loginPassWord") {
        this.$options.nodeFormTemplate.sshKeyName = "";
      } else if (loginType === "sshKeyName") {
        this.$options.nodeFormTemplate.loginPassWord = "";
        this.$options.nodeFormTemplate.loginUserName = "";
      } else {
        // 不需要做什么, 应该不会出现这种情况, 因为是让用户选择的
      }

      for (let [itemKey, itemVal] of Object.entries(this.viewItems)) {
        if (!Object.hasOwn(this.nodesOptions[0], itemKey)) {
          // 说明该键不在待提交的表单里, 可以不用管它 ; nodesOptions数组最少有三个节点
          continue;
        }

        if (
          loginType === "sshKeyName" &&
          (itemKey === "loginUserName" || itemKey === "loginPassWord")
        ) {
          continue;
        }

        if (loginType === "loginPassWord" && itemKey === "sshKeyName") {
          continue;
        }

        // 改变模板的内容
        this.$options.nodeFormTemplate[itemKey] = itemVal;

        for (let nodeOpt of this.nodesOptions) {
          // 对于每个节点
          nodeOpt[itemKey] = itemVal;
        }
      }

      this.helpers.nodeFormsDisplay = true;
    },

    logInfoSave(formName) {
      this.$refs[formName].validate((valid, unValidatedFields) => {
        // console.log(unValidatedFields);

        if (this.isValidated(unValidatedFields)) {
          // console.log("验证成功");
          this.clearSaveLogInfo();
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          console.warn("验证失败");
          this.$message({
            message: "保存失败",
            type: "warning",
          });
          return false;
        }
      });
    },

    deleteNodeForm(key) {
      const ind = this.getIndex(key);
      this.nodesOptions.splice(ind, 1);
      this.helpers.nodeFormsKeys.splice(ind, 1);
      this.helpers.validationArr.splice(ind, 1);
    },
  },
};
</script>

<style lang="less" scoped >
.apartFromNext{
  text-align: left;
              width:600px;
}
.saveButton{
  margin-left: 50px;
  position: relative;
  width: 150px;
}
#zhanwei {
  height: 50px;
  width: 100%;
  margin: 0%;
}
.nodesForms {
  margin-bottom: 0.1rem;
}

.loginTypeChoose {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.newNodeButton{
  margin:0 auto;
  width: 150px;
}
</style>